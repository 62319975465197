import React from "react";
import { Button } from "../Button";
import { APP_ROUTES } from "../../router";

import "./styles.scss";

// Links to different pages for testing purposes
export const LinksToPages = () => {
  const { home, english, spanish, mandarin } = APP_ROUTES;
  return (
    <div className="links-to-pages">
      <p>Links to pages (Debug):</p>
      <div className="links-to-pages-container">
        <Button href={home.pathId} secondary>
          Home
        </Button>

        <Button href={english.pathId} secondary>
          English Page
        </Button>

        <Button href={spanish.pathId} secondary>
          Spanish Page
        </Button>

        <Button href={mandarin.pathId} secondary>
          Mandarin Page
        </Button>
      </div>
    </div>
  );
};
