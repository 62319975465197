import React from "react";
import ReactModal from "react-modal";

import { Button } from "../Button";
import { CloseButton } from "../Buttons";

import "./styles.scss";

ReactModal.setAppElement("#root");

type Props = {
  title: string;
  description1: string;
  description2: string;
  buttonText: string;
  externalLink: string;
  isOpen: boolean;
  onClose: () => void;
};

// About modal
export const Modal = (props: Props) => {
  return (
    <ReactModal
      isOpen={props.isOpen}
      contentLabel="About Modal"
      className="modal-custom"
      overlayClassName="modal-custom-overlay"
    >
      <CloseButton onClose={props.onClose} isModal />
      <div className="modal-custom-inner">
        <h1 className="modal-custom-title">{props.title}</h1>
        <p className="modal-custom-description1">{props.description1}</p>
        <p className="modal-custom-description2">{props.description2}</p>
        <Button
          classNames="btn-learn-more"
          href={props.externalLink}
          openInNewTab
        >
          {props.buttonText}
        </Button>
      </div>
    </ReactModal>
  );
};
