import React, { useRef, useState, useEffect } from "react";
import { saveAs } from "file-saver";
import { isMobile, isMobileSafari } from "react-device-detect";
import {
  AUDIO_STATUS,
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
} from "../../../constants";
import { sendEvent, formatDuration } from "../../../utils";
import { Mode } from "../../../types";
import { ForwardRewind, DownloadButton, CircularProgressBar } from "..";

import "./styles.scss";

type Props = {
  src: string;
  title: string;
  selectedDuration: number;
  selectedSpeakerName: string;
  selectedMode: Mode;
  elapsedTime: number;
  setElapsedTime: (prev: number) => void;
  sendAudioElapsed: () => void;
};

export const nudgedProgress = (n: number, d: number): number => {
  if (d - n < 0.2) return d;
  return n;
};

export const MainPlayer = ({
  src,
  title,
  selectedDuration,
  selectedSpeakerName,
  selectedMode,
  elapsedTime,
  setElapsedTime,
  sendAudioElapsed,
}: Props) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [status, setStatus] = useState(AUDIO_STATUS.INITIAL);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [audioReady, setAudioReady] = useState(false);

  useEffect(() => {
    // Set timer
    if (status === AUDIO_STATUS.PLAYING) {
      // Store intervalId to clear the interval when the component re-renders
      const intervalId = setInterval(() => {
        setElapsedTime(elapsedTime + 1);
      }, 1000);

      // Clean interval on re-render
      return () => clearInterval(intervalId);
    }
  }, [elapsedTime, status, setElapsedTime]);

  const handleAudioReady = () => {
    // Play when audio is ready
    setAudioReady(true);
    onToggleClick();
    // Send audio started event
    sendEvent({
      category: EVENT_CATEGORIES.audio,
      action: EVENT_ACTIONS.started,
      label: getEventString(),
    });
  };

  // action or label recorded in Google Analytics
  const getEventString = () => {
    const modeString = selectedMode === "sitting" ? "sp" : "ap";
    return `${title}/${modeString}/${selectedDuration}/${
      selectedSpeakerName.split(" ")[0]
    }`;
  };

  const onToggleClick = () => {
    const audio = audioRef.current;
    if (audio) {
      audio.paused ? audio.play() : audio.pause();
      // When clicking pause
      if (audio.paused) {
        sendEvent({
          category: EVENT_CATEGORIES.audio,
          action: EVENT_ACTIONS.paused,
          label: getEventString(),
        });
        sendAudioElapsed();
      }
      // When clicking play
      else {
        setElapsedTime(0);
        sendEvent({
          category: EVENT_CATEGORIES.audio,
          action: EVENT_ACTIONS.played,
          label: getEventString(),
        });
      }
    }
  };

  const playForward = () => {
    const audio = audioRef.current;
    if (audio && currentTime < duration - 1) {
      audio.currentTime += 15;
      sendEvent({
        category: EVENT_CATEGORIES.audio,
        action: EVENT_ACTIONS.forwarded,
        label: getEventString(),
      });
    }
  };

  const playBackward = () => {
    const audio = audioRef.current;
    if (audio && audio.currentTime > 1) {
      audio.currentTime -= 15;
      sendEvent({
        category: EVENT_CATEGORIES.audio,
        action: EVENT_ACTIONS.rewound,
        label: getEventString(),
      });
    }
  };

  const handleDownload = (): void => {
    // Mobile safari can handle downloading blob file while other browsers can't
    if (isMobileSafari || !isMobile) {
      fetch(src)
        .then((res) => res.blob())
        .then((blob) => {
          saveAs(blob, title + ".mp3");
        });
    }

    sendEvent({
      category: EVENT_CATEGORIES.audio,
      action: EVENT_ACTIONS.downloaded,
      label: getEventString(),
    });
  };

  const handleCompleteAudio = (): void => {
    setStatus(AUDIO_STATUS.ENDED);
    sendEvent({
      category: EVENT_CATEGORIES.audio,
      action: EVENT_ACTIONS.completed,
      label: getEventString(),
    });
    sendAudioElapsed();
  };

  return (
    <div className="main-player">
      <CircularProgressBar
        progress={nudgedProgress(currentTime, duration)}
        trackWidth={17}
        diameter={isMobile ? 235 : 264}
        total={duration}
        // PlayPause props
        isPlaying={status === AUDIO_STATUS.PAUSED}
        status={status}
        onToggleClick={onToggleClick}
        audioReady={audioReady}
      />

      <audio
        onPlaying={() => setStatus(AUDIO_STATUS.PLAYING)}
        onPause={() => setStatus(AUDIO_STATUS.PAUSED)}
        onEnded={handleCompleteAudio}
        onDurationChange={(e) => setDuration(e.currentTarget.duration)}
        onTimeUpdate={(e) => {
          setCurrentTime(e.currentTarget.currentTime);
        }}
        ref={audioRef}
        src={src}
        onLoadedMetadata={handleAudioReady}
      />

      <div className="main-player-controls">
        <ForwardRewind type="rewind" playBackward={playBackward} />
        <p className="duration-elapsed">
          <span className="font-bold">{formatDuration(currentTime)}</span>
          {` / ${formatDuration(duration)}`}
        </p>
        <ForwardRewind type="forward" playForward={playForward} />
      </div>

      <DownloadButton
        fetchBlob={isMobile && !isMobileSafari}
        src={src}
        title={title}
        download={handleDownload}
      />
    </div>
  );
};
