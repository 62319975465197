import React from "react";
import { ReactComponent as OvalIcon3 } from "../../../../assets/icons/oval-3.svg";
import { ReactComponent as OvalIcon4 } from "../../../../assets/icons/oval-4.svg";

import "./styles.scss";

// Bottom oval icons that goes across layout & additional tool section
export const IconOvalsBottom = () => {
  return (
    <>
      <OvalIcon3 className="icon-oval-3" />
      <OvalIcon4 className="icon-oval-4" />
    </>
  );
};
