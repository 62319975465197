import { useEffect } from "react";
import { useHistory } from "react-router-dom";

// Used to push a history in option screens (mode/duration/speaker/audio)
//  This allows users to use browser back button in option screens to get to main page
export const useNavigation = () => {
  const history = useHistory();

  useEffect(() => {
    const stateName = "optionScreen";
    // Push a history if not have been pushed
    if (history.location.state !== stateName) {
      history.push({ state: stateName });
    }
    return history.listen((location) => {
      if (history.action === "POP") {
        window.location.reload();
      }
    });
  }, [history]);
};
