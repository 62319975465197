import React from "react";
import Loader from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { Tooltip } from "../../Tooltip";
import { ToggleIcon } from "./ToggleIcon";
import { AUDIO_STATUS } from "../../../constants/enum";

import "./styles.scss";

export type Props = {
  status: AUDIO_STATUS;
  onToggleClick: () => void;
  isPlaying: boolean;
  audioReady: boolean;
};

export const PlayPause = (props: Props) => {
  const { t } = useTranslation();

  return (
    <div className="play-pause">
      <Tooltip
        id={`${props.isPlaying ? "tt-play" : "tt-pause"}`}
        text={`${props.isPlaying ? t("play") : t("pause")}`}
        hideOnMobile={true}
      >
        <button onClick={props.onToggleClick} className="toggle">
          {props.audioReady ? (
            <ToggleIcon status={props.status} />
          ) : (
            <Loader
              type={"BallTriangle"}
              color="#fff"
              width={"43px"}
              height={"43px"}
            />
          )}
        </button>
      </Tooltip>
    </div>
  );
};
