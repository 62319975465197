import React from "react";
import { Button } from "../../components/Button";
import { APP_ROUTES } from "../../router";
import { Header } from "../../components/Header";
import { Layout } from "../../components/Layout";

import "./styles.scss";

export const HomePage = () => {
  const { english, spanish, mandarin } = APP_ROUTES;

  return (
    <Layout>
      <Header hideAbout={true} />
      <div className="home-page">
        <h1 className="center home-page-title">
          Please select your preferred language
        </h1>
        <h1 className="center home-page-title">
          Por favor, selecciona el lenguaje
        </h1>
        <h1 className="center home-page-title">请选择首选语言</h1>
        <div className="home-page-language-btns">
          <Button secondary href={english.pathId}>
            English
          </Button>
          <Button secondary href={spanish.pathId}>
            Español
          </Button>
          <Button secondary href={mandarin.pathId}>
            普通话
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;
