import React from "react";
import pauseIcon from "../../../assets/icons/icon-pause.svg";

type Props = {
  className?: string;
};

export const IconPause = (props: Props) => {
  return (
    <img
      className={`icon-play-pause icon-pause ${props.className}`}
      src={pauseIcon}
      alt="pause-icon"
    />
  );
};
