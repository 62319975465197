import React from "react";
import forwardIcon from "../../../assets/icons/icon-forward.png";

export const IconForward = () => {
  return (
    <img
      className={`icon-forward-rewind icon-forward`}
      src={forwardIcon}
      alt="forward-icon"
    />
  );
};
