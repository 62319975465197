import React from "react";
import { Button } from "../../Button";
import closeIcon from "../../../assets/icons/icon-close.png";
import closeIconPurple from "../../../assets/icons/icon-close-purple.png";

import "./styles.scss";

type Props = {
  onClose: () => void;
  light?: boolean;
  large?: boolean;
  isModal?: boolean;
};

export const CloseButton = (props: Props) => {
  return (
    <Button
      classNames={`btn-close ${props.isModal ? "btn-close-modal" : ""}`}
      onClick={props.onClose}
      noStyling
    >
      <img
        className={`icon-close ${props.isModal ? "icon-close-modal" : ""}`}
        alt="close-icon"
        src={`${props.isModal ? closeIconPurple : closeIcon}`}
      />
    </Button>
  );
};
