export const SITE_INFORMATION = {
  HEADER_TITLE: "hmi logo",
};

export const EVENT_CATEGORIES = {
  audio: "AUDIO",
  audioElapsed: "AUDIO_ELAPSED",
};
// Constants for event's actions for google analytics
export const EVENT_ACTIONS = {
  started: "STARTED",
  played: "PLAYED",
  paused: "PAUSED",
  forwarded: "FORWARDED",
  rewound: "REWOUND",
  reset: "RESET",
  downloaded: "DOWNLOADED",
  completed: "COMPLETED",
  elapsed: "ELAPSED",
};

export enum AUDIO_STATUS {
  INITIAL,
  PLAYING,
  PAUSED,
  ENDED,
}
