import React from "react";
import { Button } from "../../../Button";

import "./styles.scss";

type ToolProps = {
  title: string;
  description: string;
  buttonText: string;
  externalLink: string;
};

export const Tool = ({
  title,
  description,
  buttonText,
  externalLink,
}: ToolProps) => {
  return (
    <div className="tool">
      <h1 className="tool-title">{title}</h1>
      <p className="tool-description">{description}</p>
      <Button href={externalLink} openInNewTab>
        {buttonText}
      </Button>
    </div>
  );
};
