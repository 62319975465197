import React from "react";
import { useTranslation } from "react-i18next";
import { OptionScreenTemplate } from "../OptionScreenTemplate";
import { OptionScreenProps } from "../../../types";
import { Button } from "../../Button";

import "./styles.scss";

type Props = {
  durations: number[];
} & OptionScreenProps;

export const DurationScreen = (props: Props) => {
  const { t } = useTranslation();

  const pickDuration = (duration: number) => {
    props.selectOption("duration", duration);
    // Close current screen
    props.onHide();
  };

  const renderOptionCards = () => {
    return props.durations.map((duration) => {
      return (
        <Button
          classNames="btn-duration"
          key={duration}
          noStyling
          onClick={() => pickDuration(duration)}
        >
          <span className="duration-text">
            {`${
              duration === 1
                ? `${duration} ${t("min")}`
                : `${duration} ${t("mins")}`
            }`}
          </span>
        </Button>
      );
    });
  };

  return (
    <OptionScreenTemplate
      onClose={props.onClose}
      hasPrevScreen={props.hasPrevScreen}
      onBack={props.onBack}
    >
      <h1 className="center my-1">{t("choose a duration")}</h1>
      <div className="option-card-wrap option-card-wrap-duration">
        {renderOptionCards()}
      </div>
    </OptionScreenTemplate>
  );
};
