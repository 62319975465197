import React from "react";

import { HomePage } from "./screens/Home";
import { EnglishPage } from "./screens/English";
import { SpanishPage } from "./screens/Spanish";
import { MandarinPage } from "./screens/Mandarin";
import { PageNotFoundPage } from "./screens/PageNotFound";

export type AppRoute = {
  pathId: string;
  toPath: (id?: string) => string;
  component: JSX.Element;
};

const homeRoute: AppRoute = {
  pathId: "/",
  toPath: () => "/",
  component: <HomePage />,
};

const englishRoute: AppRoute = {
  pathId: "/en",
  toPath: () => "/en",
  component: <EnglishPage />,
};
const spanishRoute: AppRoute = {
  pathId: "/es",
  toPath: () => "/es",
  component: <SpanishPage />,
};

const mandarinRoute: AppRoute = {
  pathId: "/cn",
  toPath: () => "/cn",
  component: <MandarinPage />,
};

const pageNotFoundRoute: AppRoute = {
  pathId: "",
  toPath: () => "",
  component: <PageNotFoundPage />,
};

export const APP_ROUTES = {
  home: homeRoute,
  english: englishRoute,
  spanish: spanishRoute,
  mandarin: mandarinRoute,
  pageNotFound: pageNotFoundRoute,
};
