import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../Button";
import { getMinutes } from "../../../utils/string-formatter";
import { Mode, Speaker } from "../../../types";

import "./styles.scss";

type Props = {
  id: string;
  title: string;
  durations: number[];
  isCorePractice: boolean;
  modes: Mode[];
  speakers: Speaker[];
  selectCard: () => void;
};

export const PracticeCard = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Button
      noStyling
      classNames={`practice-card ${
        props.isCorePractice ? "practice-card-core" : "practice-card-core"
      }`}
      onClick={props.selectCard}
    >
      <p className="practice-card-minutes">
        {getMinutes(props.durations) === "1"
          ? getMinutes(props.durations) + ` ${t("min")}`
          : getMinutes(props.durations) + ` ${t("mins")}`}
      </p>
      <p className="practice-card-title">{props.title}</p>
    </Button>
  );
};
