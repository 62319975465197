import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import { APP_ROUTES } from "./router";
import { getHistory, initializeGA } from "./utils/react-ga";

import "./base-app.scss";

// Initialize Google Analytics
try {
  initializeGA();
} catch (err) {
  console.warn("error while initializing GA: ", err);
}

export const BaseApp = (): JSX.Element => {
  const { home, english, spanish, mandarin } = APP_ROUTES;

  return (
    <Router history={getHistory()}>
      {/* Use language context across components */}
      <div className="app">
        <Switch>
          <Route exact path={home.pathId}>
            {home.component}
          </Route>
          {/* English Pages */}
          <Route path={english.pathId}>{english.component}</Route>
          {/* Spanish Pages */}
          <Route path={spanish.pathId}>{spanish.component}</Route>
          {/* Mandarin Pages */}
          <Route path={mandarin.pathId}>{mandarin.component}</Route>
          <Route>{home.component}</Route>
        </Switch>
      </div>
    </Router>
  );
};
