import React from "react";
import {
  fractionToDegrees,
  plotArcExtremes,
  arcToSvgPath,
} from "../../../utils/arc-helpers";
import { Props as PlayPauseProps, PlayPause } from "../PlayPause";

import "./styles.scss";

type Props = {
  total: number;
  progress: number;
  diameter: number;
  trackWidth: number;
} & PlayPauseProps;

export const CircularProgressBar = (props: Props) => {
  const degrees = fractionToDegrees(props.total, props.progress);

  const center = {
    x: props.diameter / 2,
    y: props.diameter / 2,
  };

  const innerRadius = props.diameter / 2 - props.trackWidth + 5;

  const extremes = plotArcExtremes(center, innerRadius, 0, degrees % 360);
  return (
    <div
      className="circle-progress-bar"
      style={{ width: props.diameter, height: props.diameter }}
    >
      <PlayPause
        isPlaying={props.isPlaying}
        status={props.status}
        onToggleClick={props.onToggleClick}
        audioReady={props.audioReady}
      />
      <svg width={props.diameter} height={props.diameter}>
        <circle
          className="circle-progress-bar-background"
          strokeWidth={props.trackWidth}
          cx={center.x}
          cy={center.y}
          r={innerRadius}
        />

        {/* Circle when audio is complete */}
        {degrees >= 360 && (
          <circle
            className="circle-progress-bar-background circle-progress-bar-background-initial"
            strokeWidth={props.trackWidth}
            cx={center.x}
            cy={center.y}
            r={innerRadius}
          />
        )}

        <path
          className="circle-progress-bar-crawler"
          strokeLinecap="round"
          strokeWidth={props.trackWidth}
          d={arcToSvgPath(center, innerRadius, 0, degrees % 360)}
        />

        <circle
          className="circle-progress-bar-dot"
          cx={extremes.start.x}
          cy={extremes.start.y}
          r={props.trackWidth / 5}
          stroke="none"
        />
      </svg>
      <div
        className="circle-progress-bar-inner"
        style={{
          width: `${props.diameter - props.trackWidth - 20}px`,
          height: `${props.diameter - props.trackWidth - 20}px`,
          borderRadius: "50%",
          top: `${props.trackWidth + 2}px`,
          left: `${props.trackWidth + 2}px`,
        }}
      />
    </div>
  );
};
