import React from "react";
import LazyLoad from "react-lazyload";

import "./styles.scss";

type Props = {
  src: string;
};

export const IconSpeaker = (props: Props) => {
  return !!props.src ? (
    <LazyLoad height={75}>
      <img className="icon-speaker" src={props.src} alt="speaker-icon" />
    </LazyLoad>
  ) : null;
};
