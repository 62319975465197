import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IconLogo } from "../Icons";
import { Button } from "../Button";
import { Modal } from "../Modal";

import "./styles.scss";

type Props = {
  hideAbout?: boolean;
};

export const Header = (props: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <header className="main-header">
      <Modal
        title={t("about title")}
        description1={t("about description1")}
        description2={t("about description2")}
        buttonText={t("about button text")}
        externalLink={"https://hminnovations.org/"}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      />
      <div className="main-header-inner">
        <div />
        <IconLogo />
        {props.hideAbout ? (
          <div />
        ) : (
          <Button
            classNames="btn-about"
            noStyling
            onClick={() => setShowModal(true)}
          >
            {t("about")}
          </Button>
        )}
      </div>
    </header>
  );
};
