import React from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "../../Tooltip";
import { IconRewind, IconForward } from "../../Icons";
import { Button } from "../../Button";

import "./styles.scss";

type Props = {
  type: "forward" | "rewind";
  playForward?: () => void;
  playBackward?: () => void;
};
export const ForwardRewind = (props: Props) => {
  const { t } = useTranslation();
  const { type } = props;

  // is fast forward type
  const isForward = type === "forward";
  return (
    <Tooltip
      className={`${isForward ? "play-forward" : "play-backward"}`}
      id={`${isForward ? "tt-fastForward" : "ff-rewind"}`}
      text={isForward ? t("fastForward") : t("rewind")}
      hideOnMobile
    >
      <Button
        classNames="btn-forward-rewind"
        onClick={isForward ? props.playForward : props.playBackward}
        noStyling
      >
        {isForward ? <IconForward /> : <IconRewind />}
      </Button>
    </Tooltip>
  );
};
