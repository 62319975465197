import _ from "lodash";

export type Point = {
  x: number;
  y: number;
};

export type ArcExtremes = {
  start: Point;
  end: Point;
  largeArcFlag: string;
};

export function fractionToDegrees(
  denominator: number,
  numerator: number
): number {
  return _.clamp((numerator / denominator || 0) * 360, 0, 360);
}

export function arcToSvgPath(
  center: Point,
  radius: number,
  startAngle: number,
  endAngle: number
): string {
  const extremes = plotArcExtremes(
    {
      x: center.x,
      y: center.y,
    },
    radius,
    startAngle,
    endAngle
  );
  return [
    "M",
    extremes.start.x,
    extremes.start.y,
    "A",
    radius,
    radius,
    0,
    extremes.largeArcFlag,
    0,
    extremes.end.x,
    extremes.end.y,
  ].join(" ");
}

export function plotArcExtremes(
  center: Point,
  radius: number,
  startAngle: number,
  endAngle: number
): ArcExtremes {
  const start = polarToCartesian(center, radius, endAngle);
  const end = polarToCartesian(center, radius, startAngle);
  const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
  return { start, end, largeArcFlag };
}

function polarToCartesian(
  center: Point,
  radius: number,
  angleInDegrees: number
): Point {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  return {
    x: center.x + radius * Math.cos(angleInRadians),
    y: center.y + radius * Math.sin(angleInRadians),
  };
}
