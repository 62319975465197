import { AudioMetadata } from "../types";
import { SPEAKER_CN, SPEAKER_EN, SPEAKER_ES } from "./speaker";
import { optionsMap_CN, optionsMap_EN, optionsMap_ES } from "./options-map";

type Props = {
  translation: any;
  audios: AudioMetadata[];
};

const { cort, daniela, ronne, jay } = SPEAKER_EN;
const { abismael, fernanda } = SPEAKER_ES;
const { tbd } = SPEAKER_CN;

// English
export const EN: Props = {
  translation: {
    // Main page
    "choose a meditation": "Choose a meditation for this moment...",
    sortByDuration: "Sort by duration",
    disclaimer:
      "Please note that this program is not a substitute for professional mental health treatment and does not diagnose or treat illness. If you have questions, consult with your mental health provider before beginning the program.",
    // About Section
    about: "About",
    "about title": "Your Path to Well-Being. Powered by Science",
    "about description1":
      "Welcome to a selection of guided meditations to support your well-being from Healthy Minds Innovations.",
    "about description2":
      "Healthy Minds Innovations develops well-being tools from decades of neuroscientific research so you can train your mind to be more calm, focused, and resilient.",
    "about button text": "Learn More",
    // Options screen
    "choose a mode": "Choose a mode",
    sitting: "Sitting",
    active: "Active",
    "choose a duration": "Choose a duration",
    min: "min",
    mins: "mins",
    "choose a speaker": "Choose a speaker",
    // Additional tools
    additionalToolsTitle:
      "Explore Additional English-Language Well-Being Tools",
    hmpTitle: "Healthy Minds Program App",
    hmpBody:
      "Continue your practice with hours of meditation freely available on the Healthy Minds Program mobile app. Developed by scientists and expert trainers, the HMP app features a series of podcast-style lessons that teach you about the brain, as well as both seated and active practices to support your practice in a way that fits your lifestyle.",
    hmpBtnText: "Get the App Today",
    blogTitle: "Learn & Practice Opportunities",
    blogBody:
      "Get the latest in news, events, and well-being tips on the Healthy Minds Innovations Blog. It features weekly columns filled with helpful pointers on promoting a healthy mind, new and noteworthy meditations, and the science behind our work.",
    blogBtnText: "Visit Blog",
    // Mode description
    modeDescriptionSitting:
      "Train your mind in a quiet space where you can meditate either seated comfortably or lying down.",
    modeDescriptionActive:
      "Train your mind while doing a light activity like walking, housework, or exercise.",
    // Audio Player texts
    download: "Download",
    play: "Play",
    pause: "Pause",
    fastForward: "Fast forward",
    rewind: "Rewind",
  },
  audios: [
    {
      id: "0",
      category: "core",
      title: "Discover Inner Calm",
      description:
        "Cultivate a sense of inner calm by bringing mindful awareness to the world of the senses.",
      options: {
        durations: [5, 10, 15, 20, 30],
        modes: ["sitting", "active"],
        speakers: [cort, daniela],
      },
      optionsMap: optionsMap_EN.discoverInnerCalm,
    },
    {
      id: "1",
      category: "core",
      title: "Mindful Breathing",
      description:
        "Breathing naturally soothes the nervous system. In this practice you'll use your breath to calm your mind and strengthen mindfulness.",
      options: {
        durations: [5, 10, 15, 20, 30],
        modes: ["sitting", "active"],
        speakers: [cort],
      },
      optionsMap: optionsMap_EN.mindfulBreathing,
    },
    {
      id: "2",
      category: "core",
      title: "Navigating Change",
      description:
        "By exploring the experience of change through meditation, we can learn to deal with the changes we face in life with wisdom and balance.",
      options: {
        durations: [5, 10, 15, 20, 30],
        modes: ["sitting", "active"],
        speakers: [cort, daniela],
      },
      optionsMap: optionsMap_EN.navigatingChange,
    },
    {
      id: "3",
      category: "core",
      title: "Living Your Values",
      description:
        "Staying connected to our core values and principles makes us more confident and resilient. In this guided meditation, we will follow three steps to bring our values fully into our lives.",
      options: {
        durations: [5, 10, 15, 20, 30],
        modes: ["sitting", "active"],
        speakers: [cort, daniela],
      },
      optionsMap: optionsMap_EN.livingYourValues,
    },
    {
      id: "4",
      category: "single",
      title: "Mindfulness for Sleep",
      description:
        "Prepare your body and mind for a good night's sleep with this mindfulness practice.",
      options: {
        durations: [22],
        modes: ["sitting"],
        speakers: [cort],
      },
      optionsMap: optionsMap_EN.mindfulnessForSleep,
    },
    {
      id: "5",
      category: "single",
      title: "Compassion in Challenging Times",
      description:
        "Compassion puts us in touch with a tremendous source of inner strength and resilience. This practice taps into our potential to care.",
      options: {
        durations: [12],
        modes: ["sitting"],
        speakers: [cort, ronne],
      },
      optionsMap: optionsMap_EN.compassionInChallengingTimes,
    },
    {
      id: "6",
      category: "single",
      title: "Calm in the Midst of Chaos",
      description:
        "The fear and uncertainty these days can be overwhelming. This practice calms the mind and helps us tap into our inner resilience.",
      options: {
        durations: [10],
        modes: ["sitting"],
        speakers: [cort, ronne],
      },
      optionsMap: optionsMap_EN.calmInTheMidstOfChaos,
    },
    {
      id: "7",
      category: "single",
      title: "Check in with Yourself",
      description:
        "Take a few minutes to notice how your body and mind are responding to what’s happening in the world around you.",
      options: {
        durations: [6],
        modes: ["sitting", "active"],
        speakers: [jay, ronne],
      },
      optionsMap: optionsMap_EN.checkInYourself,
    },
    {
      id: "8",
      category: "single",
      title: "Feeling Stuck",
      description:
        "Are you feeling stuck? Practice using mindfulness to explore your experience and build the skills to move through it.",
      options: {
        durations: [2],
        modes: ["sitting"],
        speakers: [cort],
      },
      optionsMap: optionsMap_EN.feelingStuck,
    },
    {
      id: "9",
      category: "single",
      title: "Momentary Overwhelm",
      description:
        "Are you feeling overwhelmed? Explore what happens when you practice a few moments of kindness and compassion.",
      options: {
        durations: [2],
        modes: ["sitting"],
        speakers: [cort],
      },
      optionsMap: optionsMap_EN.momentaryOverwhelm,
    },
    {
      id: "10",
      category: "single",
      title: "Moment of Rest",
      description: "Take a short moment out of your day to get some rest.",
      options: {
        durations: [1],
        modes: ["sitting"],
        speakers: [cort],
      },
      optionsMap: optionsMap_EN.momentOfRest,
    },
  ],
};
// Spanish
export const ES: Props = {
  translation: {
    // Main page
    "choose a meditation":
      "Escoge una práctica de meditación para este momento...",
    sortByDuration: "Ordenado por duración",
    disclaimer:
      "Favor de toma en cuenta que este programa no es un substituto para un tratamiento de salud mental profesional, no realiza diagnósticos ni trata padecimientos. Si tienes dudas al respecto, consulta a tu proveedor de salud mental profesional antes de iniciar el programa.",
    // About section
    about: "A cerca de",
    "about title": "Tu camino hacia el bienestar. Impulsado por la ciencia",
    "about description1":
      "Bienvenido a la sección de meditaciones guiadas de Healthy Mind Innovationsa para promover tu binestar.",
    "about description2":
      " Durante décadas de investigación neurocientífica hemos desarrollado herramientas para el bienestar, a fin de que puedas entrenar tu mente para volverla más serena, enfocada y resiliente.",
    "about button text": "Saber más.",
    // Options screen
    "choose a mode": "Escoge el tipo de meditación",
    sitting: "Formal",
    active: "Activa",
    "choose a duration": "Escoge la duración",
    min: "minuto",
    mins: "minutos",
    "choose a speaker": "Escoge a tu instructor",
    // Additional tools
    additionalToolsTitle:
      "Explora herramientas adicionales para el bienestar en español",
    hmpTitle: "App del Healthy Minds Program",
    hmpBody:
      "Continúa practicando de manera gratuita durante horas con las meditaciones disponibles en la App del Healthy Minds Program para tu celular. Desarrollada por científicos y practicantes expertos, la App del HMP contiene una serie de lecciones a modo de podcast que te enseñarán más sobre el cerebro, así como de las prácticas formales y activas que mejor se ajusten a tu estilo de vida",
    hmpBtnText: "Obtén la App hoy mismo.",
    blogTitle: "Oportunidades  de aprendizaje y practica. ",
    blogBody:
      "Obtén las últimas noticias, eventos y consejos de bienestar en el Blog de Healthy Minds Innovations. Ahí encontrarás columnas semanales para promover la salud mental, meditaciones nuevas y destacables, así como la ciencia detrás de nuestro trabajo.",
    blogBtnText: "Visita el Blog.",
    // Mode description
    modeDescriptionSitting:
      "Entrena tu mente en un espacio slencioso en el que puedas  meditar. Cómodamente sentado o acostado.",
    modeDescriptionActive:
      "Entrena tu mente mientras realizas una actividad ligera como caminar, el quehacer del hogar o ejercicio.",
    // Audio Player texts
    download: "Descarga",
    play: "Reproducir",
    pause: "Pausa",
    fastForward: "Avance rápido",
    rewind: "Retroceso",
  },
  audios: [
    // Discover Inner calm
    {
      id: "0",
      category: "core",
      title: "Descubre la calma interior",
      description:
        "Cultiva una sensación de calma interna al llevar la atención consciente a la esfera de los sentidos.",
      options: {
        durations: [5, 10, 15, 20, 30],
        modes: ["sitting", "active"],
        speakers: [abismael, fernanda],
      },
      optionsMap: optionsMap_ES.discoverInnerCalm,
    },
    // Mindful breathing
    {
      id: "1",
      category: "core",
      title: "Respiración plena",
      description:
        "Respirar naturalmente calma al sistema nervioso. En esta práctica usaremos la respiración paratranquilizar a la mente y fortalecer la atención plena.",
      options: {
        durations: [5, 10, 15, 20, 30],
        modes: ["sitting", "active"],
        speakers: [abismael, fernanda],
      },
      optionsMap: optionsMap_ES.mindfulBreathing,
    },
    // Navigating change
    {
      id: "2",
      category: "core",
      title: "Navegando el cambio",
      description:
        "Al explorar la experiencia del cambio en la meditación, aprenderemos a lidiar con los cambios que enfrentemos en la vida con sabiduría y balance.",
      options: {
        durations: [5, 10, 15, 20, 30],
        modes: ["sitting", "active"],
        speakers: [abismael, fernanda],
      },
      optionsMap: optionsMap_ES.navigatingChange,
    },
    // Living your values
    {
      id: "3",
      category: "core",
      title: "Apóyate en tus valores",
      description:
        "Al mantenernos en contacto con nuestros valores y principios más importantes se  incrementa nuestra confianza y resiliencia. En esta meditación guiada seguiremos tres pasos para lograr tener presentes nuestros valores en el día a día.",
      options: {
        durations: [5, 10, 15, 20, 30],
        modes: ["sitting", "active"],
        speakers: [abismael, fernanda],
      },
      optionsMap: optionsMap_ES.livingYourValues,
    },
    // Mindfulness for sleep
    {
      id: "4",
      category: "single",
      title: "Atención plena para dormir",
      description:
        "Prepara tu mente para una buena noche de sueño con esta práctica de atención plena.",
      options: {
        durations: [22],
        modes: ["sitting"],
        speakers: [abismael, fernanda],
      },
      optionsMap: optionsMap_ES.mindfulnessForSleep,
    },
    // Compassion in challenging times
    {
      id: "5",
      category: "single",
      title: "Compasión en tiempos difíciles",
      description:
        "La compasión nos pone en contacto con una poderosa fuente interna de fuerza y resiliencia. Esta práctica incrementa nuestro potencial para interesarnos por los demás.",
      options: {
        durations: [12],
        modes: ["sitting"],
        speakers: [abismael, fernanda],
      },
      optionsMap: optionsMap_ES.compassionInChallengingTimes,
    },
    // Calm in the midst of chaos
    {
      id: "6",
      category: "single",
      title: "Calma en medio de la tormenta",
      description:
        "El miedo y la incertidumbre pueden llegar a ser abrumadores estos días. Esta práctica calma a la mente y nos ayuda a incrementar nuestra resiliencia.",
      options: {
        durations: [10],
        modes: ["sitting"],
        speakers: [abismael, fernanda],
      },
      optionsMap: optionsMap_ES.calmInTheMidstOfChaos,
    },
    // Check in yourself
    {
      id: "7",
      category: "single",
      title: "Contactando contigo mismo",
      description:
        "Toma unos minutos para sentir cómo tu cuerpo y tu mente reaccionan a lo que sucede a tu alrededor.",
      options: {
        durations: [6],
        modes: ["sitting", "active"],
        speakers: [abismael, fernanda],
      },
      optionsMap: optionsMap_ES.checkInYourself,
    },
    // Feeling stuck
    {
      id: "8",
      category: "single",
      title: "Sintiéndote atrapado",
      description:
        "¿Atrapado? Explora tus experiencias con la atención plena y desarrolla habilidades que traerán calma.",
      options: {
        durations: [2, 5],
        modes: ["sitting"],
        speakers: [abismael, fernanda],
      },
      optionsMap: optionsMap_ES.feelingStuck,
    },
    // Momentary overwhelm
    {
      id: "9",
      category: "single",
      title: "Abrumado por un momento",
      description:
        "¿Te sientes abrumado? Explora qué sucede cuando pones en práctica la bondad y la compasión por unos momentos.",
      options: {
        durations: [2, 5],
        modes: ["sitting"],
        speakers: [abismael, fernanda],
      },
      optionsMap: optionsMap_ES.momentaryOverwhelm,
    },
    // Moment of rest
    {
      id: "10",
      category: "single",
      title: "Momento de descanso",
      description: "Toma un tiempo del día para descansar.",
      options: {
        durations: [1, 5],
        modes: ["sitting"],
        speakers: [abismael, fernanda],
      },
      optionsMap: optionsMap_ES.momentOfRest,
    },
  ],
};
// Mandarin
export const CN: Props = {
  translation: {
    // Main page
    "choose a meditation": "选择现在要做的禅修练习。",
    sortByDuration: "按时间长短排序",
    disclaimer:
      "请注意，此课程不可替代专业的心理健康治疗，也不可诊断或治疗疾病。如有任何疑问，请在开始本课程前咨询权威的心理健康专家。",
    // About section
    about: "简介",
    "about title": "科学助力。你的健康之路",
    "about description1":
      "欢迎参加 Healthy Minds Innovations 禅修导引精选课，以维护身心健康。",
    "about description2":
      "Healthy Minds Innovations 从数十年的脑神经科学研究中开发出有益身心健康的工具，帮助你训练自己的心，让它更为平静、专注和柔韧。",
    "about button text": "了解更多",
    // Options screen
    "choose a mode": "选择一种禅修方式",
    sitting: "静态",
    active: "动态",
    "choose a duration": "选择时长",
    min: "分钟",
    mins: "分钟",
    "choose a speaker": "选择讲解人",
    // Additional tools
    additionalToolsTitle: "浏览更多有益身心的英语工具",
    hmpTitle: "“Healthy Minds Program” 应用程序",
    hmpBody:
      "跟着 Healthy Minds Program 手机应用程序上提供的禅修引导自行练习。HMP应用程序由科学家和禅修导师联合开发，通过一系列播客式的课堂为你讲解大脑相关知识，并且为你提供适合自己生活方式的静态和动态的禅修练习。",
    hmpBtnText: "立即获取应用程序",
    blogTitle: "学习和练习的机会",
    blogBody:
      "从 Healthy Minds Innovations 博客上获取最新消息、各种活动资讯和保健小知识。博客上的每周专栏会提供有益心理健康的建议、新颖特别的禅修方法以及我们工作背后的科学原理。",
    blogBtnText: "访问博客",
    // Mode description
    modeDescriptionSitting: "在安静的空间内与心互动，舒服地坐着或躺着禅修。",
    modeDescriptionActive:
      "一边进行轻松简单的活动，比如散步、做家务或做运动，一边训练你的心。",
    // Audio Player texts
    download: "下载",
    play: "播放",
    pause: "暂停",
    fastForward: "快进",
    rewind: "快退",
  },
  audios: [
    // Discover Inner Calm
    {
      id: "0",
      category: "core",
      title: "探索内在的平静",
      description: "将正念和觉察带入感官世界，培养内在的平静。",
      options: {
        durations: [5, 10, 15, 20, 30],
        modes: ["sitting", "active"],
        speakers: [tbd],
      },
      optionsMap: optionsMap_CN.discoverInnerCalm,
    },
    // Mindful Breathing
    {
      id: "1",
      category: "core",
      title: "正念呼吸",
      description:
        "自然的呼吸有助于舒缓紧绷的神经系统。在这项练习中，你将运用自己的呼吸，让心安定下来并提升正念。",
      options: {
        durations: [5, 10, 15, 20, 30],
        modes: ["sitting", "active"],
        speakers: [tbd],
      },
      optionsMap: optionsMap_CN.mindfulBreathing,
    },
    // Navigating Change
    {
      id: "2",
      category: "core",
      title: "迎接变化",
      description:
        "通过禅修，体验改变，学会巧用平衡应对自己在日常生活中所面临的变化。",
      options: {
        durations: [5, 10, 15, 20, 30],
        modes: ["sitting", "active"],
        speakers: [tbd],
      },
      optionsMap: optionsMap_CN.navigatingChange,
    },
    // Living your values
    {
      id: "3",
      category: "core",
      title: "践行自己的价值观",
      description:
        "不忘自己的核心价值观与原则能够增强我们的自信心和适应力。在这项引导禅修中，我们会透过三个步骤将自己的价值观融入生活。",
      options: {
        durations: [5, 10, 15, 20, 30],
        modes: ["sitting", "active"],
        speakers: [tbd],
      },
      optionsMap: optionsMap_CN.livingYourValues,
    },
    // Mindfulness for sleep
    {
      id: "4",
      category: "single",
      title: "睡前的正念练习",
      description: "通过这项正念练习，让身心准备好一夜好眠。",
      options: {
        durations: [22],
        modes: ["sitting"],
        speakers: [tbd],
      },
      optionsMap: optionsMap_CN.mindfulnessForSleep,
    },
    // Compassion in challenging times
    {
      id: "5",
      category: "single",
      title: "在挑战中唤起慈爱",
      description:
        "慈爱让我们能够连结庞大的内在力量和适应力之源。这项练习帮助我们唤醒悉心关怀的潜能。",
      options: {
        durations: [12],
        modes: ["sitting"],
        speakers: [tbd],
      },
      optionsMap: optionsMap_CN.compassionInChallengingTimes,
    },
    // Calm in the midst of chaos
    {
      id: "6",
      category: "single",
      title: "混乱中的平静",
      description:
        "时下大家可能会患得患失。这项练习让心安定下来，帮助我们唤醒内心的坚强柔韧。",
      options: {
        durations: [10],
        modes: ["sitting"],
        speakers: [tbd],
      },
      optionsMap: optionsMap_CN.calmInTheMidstOfChaos,
    },
    // Check in yourself
    {
      id: "7",
      category: "single",
      title: "关照自己",
      description: "花几分钟时间留意自己的身心对当下周围事物的反应。",
      options: {
        durations: [6],
        modes: ["sitting", "active"],
        speakers: [tbd],
      },
      optionsMap: optionsMap_CN.checkInYourself,
    },
    // Feeling Stuck
    {
      id: "8",
      category: "single",
      title: "陷入困境 ",
      description:
        "你是否感觉哪里卡住了？练习使用正念探索自己的感受，并建立起帮助自己走出困境的技巧。",
      options: {
        durations: [2],
        modes: ["sitting"],
        speakers: [tbd],
      },
      optionsMap: optionsMap_CN.feelingStuck,
    },
    // Momentary Overwhelm
    {
      id: "9",
      category: "single",
      title: "短暂的茫然不知所措",
      description:
        "你是否觉得茫然不知所措？尝试花一点时间做慈爱的练习，看看结果如何。",
      options: {
        durations: [2],
        modes: ["sitting"],
        speakers: [tbd],
      },
      optionsMap: optionsMap_CN.momentaryOverwhelm,
    },
    // Moment of Rest
    {
      id: "10",
      category: "single",
      title: "放松片刻",
      description: "一天之中抽点时间放松自己。",
      options: {
        durations: [1],
        modes: ["sitting"],
        speakers: [tbd],
      },
      optionsMap: optionsMap_CN.momentOfRest,
    },
  ],
};
