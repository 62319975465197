import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  src: string;
  title: string;
  fetchBlob: boolean;
  download: () => void;
};
export const DownloadButton = (props: Props) => {
  const { t } = useTranslation();

  return props.fetchBlob ? (
    <a
      className="btn-custom btn-custom-secondary btn-download"
      href={props.src}
      download={props.title}
      target="_blank"
      rel="noopener noreferrer"
      onClick={props.download}
    >
      {t("download")}
    </a>
  ) : (
    <button
      className="btn-custom btn-custom-secondary btn-download"
      onClick={props.download}
    >
      {t("download")}
    </button>
  );
};
