import { SPEAKER_EN, SPEAKER_ES, SPEAKER_CN } from "./speaker";
import { AudioOptionsMap } from "../types/audio";

const { cort, daniela, ronne, jay } = SPEAKER_EN;
const { abismael, fernanda } = SPEAKER_ES;
const { tbd } = SPEAKER_CN;

const ROOT_URL = "https://d1lxivv860f2t4.cloudfront.net";
const ROOT_URL_EN = ROOT_URL + "/english/";
const ROOT_URL_ES = ROOT_URL + "/spanish/";
const ROOT_URL_CN = ROOT_URL + "/chinese/";

const filePrefixes = {
  discoverInnerCalm: "tour_of_the_senses_",
  mindfulBreathing: "mindful_breathing_",
  navigatingChange: "exploring_change_",
  livingYourValues: "living_your_values_",
  mindfulnessForSleep: "single_dailylife_mindfulness_for_sleep",
  compassionInChallengingTimes: "single_covid_compassion_in_challenging_times",
  feelingStuck: "single_dailylife_feeling_stuck",
  momentaryOverwhelm: "single_micro_momentary_overwhelm",
  momentOfRest: "single_micro_moment_of_rest",
};

type Props = {
  [key: string]: AudioOptionsMap[];
};

// options map to play corresponding audio with media url
export const optionsMap_EN: Props = {
  discoverInnerCalm: [
    {
      mode: "sitting",
      duration: 5,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.discoverInnerCalm}sp_05_cd.mp3`,
    },
    {
      mode: "sitting",
      duration: 10,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.discoverInnerCalm}sp_10_cd.mp3`,
    },
    {
      mode: "sitting",
      duration: 15,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.discoverInnerCalm}sp_15_cd.mp3`,
    },
    {
      mode: "sitting",
      duration: 20,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.discoverInnerCalm}sp_20_cd.mp3`,
    },
    {
      mode: "sitting",
      duration: 30,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.discoverInnerCalm}sp_30_cd.mp3`,
    },
    {
      mode: "active",
      duration: 5,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.discoverInnerCalm}ap_05_cd.mp3`,
    },
    {
      mode: "active",
      duration: 10,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.discoverInnerCalm}ap_10_cd.mp3`,
    },
    {
      mode: "active",
      duration: 15,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.discoverInnerCalm}ap_15_cd.mp3`,
    },
    {
      mode: "active",
      duration: 20,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.discoverInnerCalm}ap_20_cd.mp3`,
    },
    {
      mode: "active",
      duration: 30,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.discoverInnerCalm}ap_30_cd.mp3`,
    },

    {
      mode: "sitting",
      duration: 5,
      speaker: daniela,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.discoverInnerCalm}sp_05_dl.mp3`,
    },
    {
      mode: "sitting",
      duration: 10,
      speaker: daniela,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.discoverInnerCalm}sp_10_dl.mp3`,
    },
    {
      mode: "sitting",
      duration: 15,
      speaker: daniela,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.discoverInnerCalm}sp_15_dl.mp3`,
    },
    {
      mode: "sitting",
      duration: 20,
      speaker: daniela,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.discoverInnerCalm}sp_20_dl.mp3`,
    },
    {
      mode: "sitting",
      duration: 30,
      speaker: daniela,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.discoverInnerCalm}sp_30_dl.mp3`,
    },
    {
      mode: "active",
      duration: 5,
      speaker: daniela,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.discoverInnerCalm}ap_05_dl.mp3`,
    },
    {
      mode: "active",
      duration: 10,
      speaker: daniela,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.discoverInnerCalm}ap_10_dl.mp3`,
    },
    {
      mode: "active",
      duration: 15,
      speaker: daniela,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.discoverInnerCalm}ap_15_dl.mp3`,
    },
    {
      mode: "active",
      duration: 20,
      speaker: daniela,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.discoverInnerCalm}ap_20_dl.mp3`,
    },
    {
      mode: "active",
      duration: 30,
      speaker: daniela,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.discoverInnerCalm}ap_30_dl.mp3`,
    },
  ],

  mindfulBreathing: [
    {
      mode: "sitting",
      duration: 5,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.mindfulBreathing}sp_05_cd.mp3`,
    },
    {
      mode: "sitting",
      duration: 10,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.mindfulBreathing}sp_10_cd.mp3`,
    },
    {
      mode: "sitting",
      duration: 15,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.mindfulBreathing}sp_15_cd.mp3`,
    },
    {
      mode: "sitting",
      duration: 20,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.mindfulBreathing}sp_20_cd.mp3`,
    },
    {
      mode: "sitting",
      duration: 30,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.mindfulBreathing}sp_30_cd.mp3`,
    },
    {
      mode: "active",
      duration: 5,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.mindfulBreathing}ap_05_cd.mp3`,
    },
    {
      mode: "active",
      duration: 10,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.mindfulBreathing}ap_10_cd.mp3`,
    },
    {
      mode: "active",
      duration: 15,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.mindfulBreathing}ap_15_cd.mp3`,
    },
    {
      mode: "active",
      duration: 20,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.mindfulBreathing}ap_20_cd.mp3`,
    },
    {
      mode: "active",
      duration: 30,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.mindfulBreathing}ap_30_cd.mp3`,
    },
  ],

  navigatingChange: [
    {
      mode: "sitting",
      duration: 5,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.navigatingChange}sp_05_cd.mp3`,
    },
    {
      mode: "sitting",
      duration: 10,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.navigatingChange}sp_10_cd.mp3`,
    },
    {
      mode: "sitting",
      duration: 15,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.navigatingChange}sp_15_cd.mp3`,
    },
    {
      mode: "sitting",
      duration: 20,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.navigatingChange}sp_20_cd.mp3`,
    },
    {
      mode: "sitting",
      duration: 30,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.navigatingChange}sp_30_cd.mp3`,
    },

    {
      mode: "active",
      duration: 5,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.navigatingChange}ap_05_cd.mp3`,
    },
    {
      mode: "active",
      duration: 10,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.navigatingChange}ap_10_cd.mp3`,
    },
    {
      mode: "active",
      duration: 15,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.navigatingChange}ap_15_cd.mp3`,
    },
    {
      mode: "active",
      duration: 20,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.navigatingChange}ap_20_cd.mp3`,
    },
    {
      mode: "active",
      duration: 30,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.navigatingChange}ap_30_cd.mp3`,
    },

    {
      mode: "sitting",
      duration: 5,
      speaker: daniela,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.navigatingChange}sp_05_dl.mp3`,
    },
    {
      mode: "sitting",
      duration: 10,
      speaker: daniela,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.navigatingChange}sp_10_dl.mp3`,
    },
    {
      mode: "sitting",
      duration: 15,
      speaker: daniela,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.navigatingChange}sp_15_dl.mp3`,
    },
    {
      mode: "sitting",
      duration: 20,
      speaker: daniela,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.navigatingChange}sp_20_dl.mp3`,
    },
    {
      mode: "sitting",
      duration: 30,
      speaker: daniela,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.navigatingChange}sp_30_dl.mp3`,
    },

    {
      mode: "active",
      duration: 5,
      speaker: daniela,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.navigatingChange}ap_05_dl.mp3`,
    },
    {
      mode: "active",
      duration: 10,
      speaker: daniela,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.navigatingChange}ap_10_dl.mp3`,
    },
    {
      mode: "active",
      duration: 15,
      speaker: daniela,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.navigatingChange}ap_15_dl.mp3`,
    },
    {
      mode: "active",
      duration: 20,
      speaker: daniela,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.navigatingChange}ap_20_dl.mp3`,
    },
    {
      mode: "active",
      duration: 30,
      speaker: daniela,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.navigatingChange}ap_30_dl.mp3`,
    },
  ],
  livingYourValues: [
    {
      mode: "sitting",
      duration: 5,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.livingYourValues}sp_05_cd.mp3`,
    },
    {
      mode: "sitting",
      duration: 10,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.livingYourValues}sp_10_cd.mp3`,
    },
    {
      mode: "sitting",
      duration: 15,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.livingYourValues}sp_15_cd.mp3`,
    },
    {
      mode: "sitting",
      duration: 20,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.livingYourValues}sp_20_cd.mp3`,
    },
    {
      mode: "sitting",
      duration: 30,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.livingYourValues}sp_30_cd.mp3`,
    },

    {
      mode: "active",
      duration: 5,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.livingYourValues}ap_05_cd.mp3`,
    },
    {
      mode: "active",
      duration: 10,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.livingYourValues}ap_10_cd.mp3`,
    },
    {
      mode: "active",
      duration: 15,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.livingYourValues}ap_15_cd.mp3`,
    },
    {
      mode: "active",
      duration: 20,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.livingYourValues}ap_20_cd.mp3`,
    },
    {
      mode: "active",
      duration: 30,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.livingYourValues}ap_30_cd.mp3`,
    },

    {
      mode: "sitting",
      duration: 5,
      speaker: daniela,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.livingYourValues}sp_05_dl.mp3`,
    },
    {
      mode: "sitting",
      duration: 10,
      speaker: daniela,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.livingYourValues}sp_10_dl.mp3`,
    },
    {
      mode: "sitting",
      duration: 15,
      speaker: daniela,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.livingYourValues}sp_15_dl.mp3`,
    },
    {
      mode: "sitting",
      duration: 20,
      speaker: daniela,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.livingYourValues}sp_20_dl.mp3`,
    },
    {
      mode: "sitting",
      duration: 30,
      speaker: daniela,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.livingYourValues}sp_30_dl.mp3`,
    },

    {
      mode: "active",
      duration: 5,
      speaker: daniela,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.livingYourValues}ap_05_dl.mp3`,
    },
    {
      mode: "active",
      duration: 10,
      speaker: daniela,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.livingYourValues}ap_10_dl.mp3`,
    },
    {
      mode: "active",
      duration: 15,
      speaker: daniela,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.livingYourValues}ap_15_dl.mp3`,
    },
    {
      mode: "active",
      duration: 20,
      speaker: daniela,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.livingYourValues}ap_20_dl.mp3`,
    },
    {
      mode: "active",
      duration: 30,
      speaker: daniela,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.livingYourValues}ap_30_dl.mp3`,
    },
  ],

  mindfulnessForSleep: [
    {
      mode: "sitting",
      duration: 22,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.mindfulnessForSleep}.mp3`,
    },
  ],

  compassionInChallengingTimes: [
    {
      mode: "sitting",
      duration: 12,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.compassionInChallengingTimes}.mp3`,
    },
    {
      mode: "sitting",
      duration: 12,
      speaker: ronne,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.compassionInChallengingTimes}_rws.mp3`,
    },
  ],

  calmInTheMidstOfChaos: [
    {
      mode: "sitting",
      duration: 10,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}single_covid_calm_in_the_midst_of_chaos.mp3`,
    },
    {
      mode: "sitting",
      duration: 10,
      speaker: ronne,
      mediaUrl: `${ROOT_URL_EN}single_covid_calm_in_the_midst_chaos_rws.mp3`,
    },
  ],

  checkInYourself: [
    {
      mode: "sitting",
      duration: 6,
      speaker: jay,
      mediaUrl: `${ROOT_URL_EN}single_covid_checking_in_with_yourself_sp_05_jv.mp3`,
    },
    {
      mode: "active",
      duration: 6,
      speaker: jay,
      mediaUrl: `${ROOT_URL_EN}single_covid_checking_in_with_yourself_ap_05_jv.mp3`,
    },
    {
      mode: "sitting",
      duration: 6,
      speaker: ronne,
      mediaUrl: `${ROOT_URL_EN}single_covid_checking_in_sp_rws.mp3`,
    },
    {
      mode: "active",
      duration: 6,
      speaker: ronne,
      mediaUrl: `${ROOT_URL_EN}single_covid_checking_in_ap_rws.mp3`,
    },
  ],

  feelingStuck: [
    {
      mode: "sitting",
      duration: 2,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.feelingStuck}.mp3`,
    },
  ],

  momentaryOverwhelm: [
    {
      mode: "sitting",
      duration: 2,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.momentaryOverwhelm}.mp3`,
    },
  ],

  momentOfRest: [
    {
      mode: "sitting",
      duration: 1,
      speaker: cort,
      mediaUrl: `${ROOT_URL_EN}${filePrefixes.momentOfRest}.mp3`,
    },
  ],
};

export const optionsMap_ES: Props = {
  discoverInnerCalm: [
    {
      mode: "sitting",
      duration: 5,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.discoverInnerCalm}sp_05_espanol_ar.mp3`,
    },
    {
      mode: "sitting",
      duration: 10,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.discoverInnerCalm}sp_10_espanol_ar.mp3`,
    },
    {
      mode: "sitting",
      duration: 15,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.discoverInnerCalm}sp_15_espanol_ar.mp3`,
    },
    {
      mode: "sitting",
      duration: 20,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.discoverInnerCalm}sp_20_espanol_ar.mp3`,
    },
    {
      mode: "sitting",
      duration: 30,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.discoverInnerCalm}sp_30_espanol_ar.mp3`,
    },
    {
      mode: "active",
      duration: 5,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.discoverInnerCalm}ap_05_espanol_ar.mp3`,
    },
    {
      mode: "active",
      duration: 10,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.discoverInnerCalm}ap_10_espanol_ar.mp3`,
    },
    {
      mode: "active",
      duration: 15,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.discoverInnerCalm}ap_15_espanol_ar.mp3`,
    },
    {
      mode: "active",
      duration: 20,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.discoverInnerCalm}ap_20_espanol_ar.mp3`,
    },
    {
      mode: "active",
      duration: 30,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.discoverInnerCalm}ap_30_espanol_ar.mp3`,
    },

    {
      mode: "sitting",
      duration: 5,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.discoverInnerCalm}sp_05_espanol_ft.mp3`,
    },
    {
      mode: "sitting",
      duration: 10,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.discoverInnerCalm}sp_10_espanol_ft.mp3`,
    },
    {
      mode: "sitting",
      duration: 15,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.discoverInnerCalm}sp_15_espanol_ft.mp3`,
    },
    {
      mode: "sitting",
      duration: 20,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.discoverInnerCalm}sp_20_espanol_ft.mp3`,
    },
    {
      mode: "sitting",
      duration: 30,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.discoverInnerCalm}sp_30_espanol_ft.mp3`,
    },
    {
      mode: "active",
      duration: 5,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.discoverInnerCalm}ap_05_espanol_ft.mp3`,
    },
    {
      mode: "active",
      duration: 10,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.discoverInnerCalm}ap_10_espanol_ft.mp3`,
    },
    {
      mode: "active",
      duration: 15,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.discoverInnerCalm}ap_15_espanol_ft.mp3`,
    },
    {
      mode: "active",
      duration: 20,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.discoverInnerCalm}ap_20_espanol_ft.mp3`,
    },
    {
      mode: "active",
      duration: 30,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.discoverInnerCalm}ap_30_espanol_ft.mp3`,
    },
  ],

  mindfulBreathing: [
    {
      mode: "sitting",
      duration: 5,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.mindfulBreathing}sp_05_espanol_ar.mp3`,
    },
    {
      mode: "sitting",
      duration: 10,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.mindfulBreathing}sp_10_espanol_ar.mp3`,
    },
    {
      mode: "sitting",
      duration: 15,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.mindfulBreathing}sp_15_espanol_ar.mp3`,
    },
    {
      mode: "sitting",
      duration: 20,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.mindfulBreathing}sp_20_espanol_ar.mp3`,
    },
    {
      mode: "sitting",
      duration: 30,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.mindfulBreathing}sp_30_espanol_ar.mp3`,
    },
    {
      mode: "active",
      duration: 5,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.mindfulBreathing}ap_05_espanol_ar.mp3`,
    },
    {
      mode: "active",
      duration: 10,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.mindfulBreathing}ap_10_espanol_ar.mp3`,
    },
    {
      mode: "active",
      duration: 15,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.mindfulBreathing}ap_15_espanol_ar.mp3`,
    },
    {
      mode: "active",
      duration: 20,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.mindfulBreathing}ap_20_espanol_ar.mp3`,
    },
    {
      mode: "active",
      duration: 30,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.mindfulBreathing}ap_30_espanol_ar.mp3`,
    },

    {
      mode: "sitting",
      duration: 5,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.mindfulBreathing}sp_05_espanol_ft.mp3`,
    },
    {
      mode: "sitting",
      duration: 10,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.mindfulBreathing}sp_10_espanol_ft.mp3`,
    },
    {
      mode: "sitting",
      duration: 15,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.mindfulBreathing}sp_15_espanol_ft.mp3`,
    },
    {
      mode: "sitting",
      duration: 20,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.mindfulBreathing}sp_20_espanol_ft.mp3`,
    },
    {
      mode: "sitting",
      duration: 30,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.mindfulBreathing}sp_30_espanol_ft.mp3`,
    },
    {
      mode: "active",
      duration: 5,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.mindfulBreathing}ap_05_espanol_ft.mp3`,
    },
    {
      mode: "active",
      duration: 10,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.mindfulBreathing}ap_10_espanol_ft.mp3`,
    },
    {
      mode: "active",
      duration: 15,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.mindfulBreathing}ap_15_espanol_ft.mp3`,
    },
    {
      mode: "active",
      duration: 20,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.mindfulBreathing}ap_20_espanol_ft.mp3`,
    },
    {
      mode: "active",
      duration: 30,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.mindfulBreathing}ap_30_espanol_ft.mp3`,
    },
  ],

  navigatingChange: [
    {
      mode: "sitting",
      duration: 5,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.navigatingChange}sp_05_espanol_ar.mp3`,
    },
    {
      mode: "sitting",
      duration: 10,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.navigatingChange}sp_10_espanol_ar.mp3`,
    },
    {
      mode: "sitting",
      duration: 15,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.navigatingChange}sp_15_espanol_ar.mp3`,
    },
    {
      mode: "sitting",
      duration: 20,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.navigatingChange}sp_20_espanol_ar.mp3`,
    },
    {
      mode: "sitting",
      duration: 30,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.navigatingChange}sp_30_espanol_ar.mp3`,
    },

    {
      mode: "active",
      duration: 5,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.navigatingChange}ap_05_espanol_ar.mp3`,
    },
    {
      mode: "active",
      duration: 10,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.navigatingChange}ap_10_espanol_ar.mp3`,
    },
    {
      mode: "active",
      duration: 15,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.navigatingChange}ap_15_espanol_ar.mp3`,
    },
    {
      mode: "active",
      duration: 20,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.navigatingChange}ap_20_espanol_ar.mp3`,
    },
    {
      mode: "active",
      duration: 30,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.navigatingChange}ap_30_espanol_ar.mp3`,
    },

    {
      mode: "sitting",
      duration: 5,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.navigatingChange}sp_05_espanol_ft.mp3`,
    },
    {
      mode: "sitting",
      duration: 10,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.navigatingChange}sp_10_espanol_ft.mp3`,
    },
    {
      mode: "sitting",
      duration: 15,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.navigatingChange}sp_15_espanol_ft.mp3`,
    },
    {
      mode: "sitting",
      duration: 20,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.navigatingChange}sp_20_espanol_ft.mp3`,
    },
    {
      mode: "sitting",
      duration: 30,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.navigatingChange}sp_30_espanol_ft.mp3`,
    },

    {
      mode: "active",
      duration: 5,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.navigatingChange}ap_05_espanol_ft.mp3`,
    },
    {
      mode: "active",
      duration: 10,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.navigatingChange}ap_10_espanol_ft.mp3`,
    },
    {
      mode: "active",
      duration: 15,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.navigatingChange}ap_15_espanol_ft.mp3`,
    },
    {
      mode: "active",
      duration: 20,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.navigatingChange}ap_20_espanol_ft.mp3`,
    },
    {
      mode: "active",
      duration: 30,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.navigatingChange}ap_30_espanol_ft.mp3`,
    },
  ],
  livingYourValues: [
    {
      mode: "sitting",
      duration: 5,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.livingYourValues}sp_05_espanol_ar.mp3`,
    },
    {
      mode: "sitting",
      duration: 10,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.livingYourValues}sp_10_espanol_ar.mp3`,
    },
    {
      mode: "sitting",
      duration: 15,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.livingYourValues}sp_15_espanol_ar.mp3`,
    },
    {
      mode: "sitting",
      duration: 20,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.livingYourValues}sp_20_espanol_ar.mp3`,
    },
    {
      mode: "sitting",
      duration: 30,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.livingYourValues}sp_30_espanol_ar.mp3`,
    },

    {
      mode: "active",
      duration: 5,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.livingYourValues}ap_05_espanol_ar.mp3`,
    },
    {
      mode: "active",
      duration: 10,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.livingYourValues}ap_10_espanol_ar.mp3`,
    },
    {
      mode: "active",
      duration: 15,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.livingYourValues}ap_15_espanol_ar.mp3`,
    },
    {
      mode: "active",
      duration: 20,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.livingYourValues}ap_20_espanol_ar.mp3`,
    },
    {
      mode: "active",
      duration: 30,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.livingYourValues}ap_30_espanol_ar.mp3`,
    },

    {
      mode: "sitting",
      duration: 5,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.livingYourValues}sp_05_espanol_ft.mp3`,
    },
    {
      mode: "sitting",
      duration: 10,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.livingYourValues}sp_10_espanol_ft.mp3`,
    },
    {
      mode: "sitting",
      duration: 15,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.livingYourValues}sp_15_espanol_ft.mp3`,
    },
    {
      mode: "sitting",
      duration: 20,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.livingYourValues}sp_20_espanol_ft.mp3`,
    },
    {
      mode: "sitting",
      duration: 30,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.livingYourValues}sp_30_espanol_ft.mp3`,
    },

    {
      mode: "active",
      duration: 5,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.livingYourValues}ap_05_espanol_ft.mp3`,
    },
    {
      mode: "active",
      duration: 10,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.livingYourValues}ap_10_espanol_ft.mp3`,
    },
    {
      mode: "active",
      duration: 15,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.livingYourValues}ap_15_espanol_ft.mp3`,
    },
    {
      mode: "active",
      duration: 20,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.livingYourValues}ap_20_espanol_ft.mp3`,
    },
    {
      mode: "active",
      duration: 30,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.livingYourValues}ap_30_espanol_ft.mp3`,
    },
  ],

  mindfulnessForSleep: [
    {
      mode: "sitting",
      duration: 22,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.mindfulnessForSleep}_espanol_ar.mp3`,
    },
    {
      mode: "sitting",
      duration: 22,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.mindfulnessForSleep}_espanol_ft.mp3`,
    },
  ],

  compassionInChallengingTimes: [
    {
      mode: "sitting",
      duration: 12,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.compassionInChallengingTimes}_espanol_ar.mp3`,
    },
    {
      mode: "sitting",
      duration: 12,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.compassionInChallengingTimes}_espanol_ft.mp3`,
    },
  ],

  calmInTheMidstOfChaos: [
    {
      mode: "sitting",
      duration: 10,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}single_covid_calm_in_the_midst_of_chaos_espanol_ar.mp3`,
    },
    {
      mode: "sitting",
      duration: 10,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}single_covid_calm_in_the_midst_of_chaos_espanol_ft.mp3`,
    },
  ],

  checkInYourself: [
    {
      mode: "sitting",
      duration: 6,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}single_covid_checking_in_with_yourself_sp_05_espanol_ar.mp3`,
    },
    {
      mode: "active",
      duration: 6,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}single_covid_checking_in_with_yourself_ap_05_espanol_ar.mp3`,
    },
    {
      mode: "sitting",
      duration: 6,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}single_covid_checking_in_with_yourself_sp_05_espanol_ft.mp3`,
    },
    {
      mode: "active",
      duration: 6,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}single_covid_checking_in_with_yourself_ap_05_espanol_ft.mp3`,
    },
  ],

  feelingStuck: [
    {
      mode: "sitting",
      duration: 2,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.feelingStuck}_02_espanol_ar.mp3`,
    },
    {
      mode: "sitting",
      duration: 5,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.feelingStuck}_05_espanol_ar.mp3`,
    },
    {
      mode: "sitting",
      duration: 2,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.feelingStuck}_02_espanol_ft.mp3`,
    },
    {
      mode: "sitting",
      duration: 5,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.feelingStuck}_05_espanol_ft.mp3`,
    },
  ],

  momentaryOverwhelm: [
    {
      mode: "sitting",
      duration: 2,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.momentaryOverwhelm}_02_espanol_ar.mp3`,
    },
    {
      mode: "sitting",
      duration: 5,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.momentaryOverwhelm}_05_espanol_ar.mp3`,
    },
    {
      mode: "sitting",
      duration: 2,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.momentaryOverwhelm}_02_espanol_ft.mp3`,
    },
    {
      mode: "sitting",
      duration: 5,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.momentaryOverwhelm}_05_espanol_ft.mp3`,
    },
  ],

  momentOfRest: [
    {
      mode: "sitting",
      duration: 1,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.momentOfRest}_01_espanol_ar.mp3`,
    },
    {
      mode: "sitting",
      duration: 5,
      speaker: abismael,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.momentOfRest}_05_espanol_ar.mp3`,
    },
    {
      mode: "sitting",
      duration: 1,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.momentOfRest}_01_espanol_ft.mp3`,
    },
    {
      mode: "sitting",
      duration: 5,
      speaker: fernanda,
      mediaUrl: `${ROOT_URL_ES}${filePrefixes.momentOfRest}_05_espanol_ft.mp3`,
    },
  ],
};

export const optionsMap_CN: Props = {
  discoverInnerCalm: [
    {
      mode: "sitting",
      duration: 5,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.discoverInnerCalm}sp_05_mandarin.mp3`,
    },
    {
      mode: "sitting",
      duration: 10,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.discoverInnerCalm}sp_10_mandarin.mp3`,
    },
    {
      mode: "sitting",
      duration: 15,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.discoverInnerCalm}sp_15_mandarin.mp3`,
    },
    {
      mode: "sitting",
      duration: 20,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.discoverInnerCalm}sp_20_mandarin.mp3`,
    },
    {
      mode: "sitting",
      duration: 30,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.discoverInnerCalm}sp_30_mandarin.mp3`,
    },
    {
      mode: "active",
      duration: 5,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.discoverInnerCalm}ap_05_mandarin.mp3`,
    },
    {
      mode: "active",
      duration: 10,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.discoverInnerCalm}ap_10_mandarin.mp3`,
    },
    {
      mode: "active",
      duration: 15,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.discoverInnerCalm}ap_15_mandarin.mp3`,
    },
    {
      mode: "active",
      duration: 20,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.discoverInnerCalm}ap_20_mandarin.mp3`,
    },
    {
      mode: "active",
      duration: 30,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.discoverInnerCalm}ap_30_mandarin.mp3`,
    },
  ],

  mindfulBreathing: [
    {
      mode: "sitting",
      duration: 5,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.mindfulBreathing}sp_05_mandarin.mp3`,
    },
    {
      mode: "sitting",
      duration: 10,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.mindfulBreathing}sp_10_mandarin.mp3`,
    },
    {
      mode: "sitting",
      duration: 15,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.mindfulBreathing}sp_15_mandarin.mp3`,
    },
    {
      mode: "sitting",
      duration: 20,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.mindfulBreathing}sp_20_mandarin.mp3`,
    },
    {
      mode: "sitting",
      duration: 30,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.mindfulBreathing}sp_30_mandarin.mp3`,
    },
    {
      mode: "active",
      duration: 5,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.mindfulBreathing}ap_05_mandarin.mp3`,
    },
    {
      mode: "active",
      duration: 10,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.mindfulBreathing}ap_10_mandarin.mp3`,
    },
    {
      mode: "active",
      duration: 15,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.mindfulBreathing}ap_15_mandarin.mp3`,
    },
    {
      mode: "active",
      duration: 20,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.mindfulBreathing}ap_20_mandarin.mp3`,
    },
    {
      mode: "active",
      duration: 30,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.mindfulBreathing}ap_30_mandarin.mp3`,
    },
  ],

  navigatingChange: [
    {
      mode: "sitting",
      duration: 5,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.navigatingChange}sp_05_mandarin.mp3`,
    },
    {
      mode: "sitting",
      duration: 10,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.navigatingChange}sp_10_mandarin.mp3`,
    },
    {
      mode: "sitting",
      duration: 15,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.navigatingChange}sp_15_mandarin.mp3`,
    },
    {
      mode: "sitting",
      duration: 20,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.navigatingChange}sp_20_mandarin.mp3`,
    },
    {
      mode: "sitting",
      duration: 30,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.navigatingChange}sp_30_mandarin.mp3`,
    },

    {
      mode: "active",
      duration: 5,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.navigatingChange}ap_05_mandarin.mp3`,
    },
    {
      mode: "active",
      duration: 10,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.navigatingChange}ap_10_mandarin.mp3`,
    },
    {
      mode: "active",
      duration: 15,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.navigatingChange}ap_15_mandarin.mp3`,
    },
    {
      mode: "active",
      duration: 20,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.navigatingChange}ap_20_mandarin.mp3`,
    },
    {
      mode: "active",
      duration: 30,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.navigatingChange}ap_30_mandarin.mp3`,
    },
  ],
  livingYourValues: [
    {
      mode: "sitting",
      duration: 5,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.livingYourValues}sp_05_mandarin.mp3`,
    },
    {
      mode: "sitting",
      duration: 10,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.livingYourValues}sp_10_mandarin.mp3`,
    },
    {
      mode: "sitting",
      duration: 15,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.livingYourValues}sp_15_mandarin.mp3`,
    },
    {
      mode: "sitting",
      duration: 20,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.livingYourValues}sp_20_mandarin.mp3`,
    },
    {
      mode: "sitting",
      duration: 30,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.livingYourValues}sp_30_mandarin.mp3`,
    },

    {
      mode: "active",
      duration: 5,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.livingYourValues}ap_05_mandarin.mp3`,
    },
    {
      mode: "active",
      duration: 10,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.livingYourValues}ap_10_mandarin.mp3`,
    },
    {
      mode: "active",
      duration: 15,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.livingYourValues}ap_15_mandarin.mp3`,
    },
    {
      mode: "active",
      duration: 20,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.livingYourValues}ap_20_mandarin.mp3`,
    },
    {
      mode: "active",
      duration: 30,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.livingYourValues}ap_30_mandarin.mp3`,
    },
  ],
  mindfulnessForSleep: [
    {
      mode: "sitting",
      duration: 22,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.mindfulnessForSleep}_mandarin.mp3`,
    },
  ],
  compassionInChallengingTimes: [
    {
      mode: "sitting",
      duration: 12,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.compassionInChallengingTimes}_mandarin.mp3`,
    },
  ],
  calmInTheMidstOfChaos: [
    {
      mode: "sitting",
      duration: 10,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}single_covid_calm_in_the_midst_of_chaos_mandarin.mp3`,
    },
  ],
  checkInYourself: [
    {
      mode: "sitting",
      duration: 6,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}single_covid_checking_in_with_yourself_sp_05_mandarin.mp3`,
    },
    {
      mode: "active",
      duration: 6,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}single_covid_checking_in_with_yourself_ap_05_mandarin.mp3`,
    },
  ],
  feelingStuck: [
    {
      mode: "sitting",
      duration: 2,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.feelingStuck}_02_mandarin.mp3`,
    },
  ],
  momentaryOverwhelm: [
    {
      mode: "sitting",
      duration: 2,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.momentaryOverwhelm}_02_mandarin.mp3`,
    },
  ],
  momentOfRest: [
    {
      mode: "sitting",
      duration: 1,
      speaker: tbd,
      mediaUrl: `${ROOT_URL_CN}${filePrefixes.momentOfRest}_01_mandarin.mp3`,
    },
  ],
};
