import React from "react";
import { useTranslation } from "react-i18next";
import { OptionScreenTemplate } from "../OptionScreenTemplate";
import { Mode, OptionScreenProps } from "../../../types";
import { Button } from "../../Button";
import { IconSitting, IconActive } from "../../Icons";

import "./styles.scss";

type Props = {
  modes: Mode[];
} & OptionScreenProps;

export const ModeScreen = (props: Props) => {
  const { t } = useTranslation();

  const pickMode = (mode: Mode) => {
    props.selectOption("mode", mode);
    // Close current screen
    props.onHide();
  };

  const renderIcon = (mode: Mode) => {
    if (mode === "sitting") {
      return <IconSitting />;
    }
    return <IconActive />;
  };

  const renderOptionCards = () => {
    return props.modes.map((mode) => {
      return (
        <div className="mode-with-description" key={mode}>
          <Button noStyling onClick={() => pickMode(mode)}>
            <div className="icon-mode-wrap">
              {renderIcon(mode)}
              <span>{mode === "sitting" ? t("sitting") : t("active")}</span>
            </div>
          </Button>
          <div className="mode-description">
            {mode === "sitting" ? (
              <span>{t("modeDescriptionSitting")}</span>
            ) : (
              <span>{t("modeDescriptionActive")}</span>
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <OptionScreenTemplate
      onClose={props.onClose}
      hasPrevScreen={props.hasPrevScreen}
      onBack={props.onBack}
    >
      <h1 className="center my-1">{t("choose a mode")}</h1>
      <div className="option-card-wrap">{renderOptionCards()}</div>
    </OptionScreenTemplate>
  );
};
