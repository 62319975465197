import React from "react";
import { useTranslation } from "react-i18next";
import { OptionScreenTemplate } from "../OptionScreenTemplate";
import { Speaker, OptionScreenProps } from "../../../types";
import { Button } from "../../Button";
import { IconSpeaker } from "../../Icons";

import "./styles.scss";

type Props = {
  speakers: Speaker[];
} & OptionScreenProps;

export const SpeakerScreen = (props: Props) => {
  const { t } = useTranslation();

  const pickSpeaker = (speaker: Speaker) => {
    props.selectOption("speaker", speaker);
    // Close current screen
    props.onHide();
  };

  const renderOptionCards = () => {
    return props.speakers.map((speaker) => {
      return (
        <Button
          classNames="btn-speaker"
          key={speaker.name}
          noStyling
          onClick={() => pickSpeaker(speaker)}
        >
          <IconSpeaker src={speaker.imgUrl} />
          <p className="text-speaker-name">{`${speaker.name}`}</p>
        </Button>
      );
    });
  };

  return (
    <OptionScreenTemplate
      onClose={props.onClose}
      hasPrevScreen={props.hasPrevScreen}
      onBack={props.onBack}
    >
      <h1 className="center my-1">{t("choose a speaker")}</h1>
      <div className="option-card-wrap option-card-wrap-speaker">
        {renderOptionCards()}
      </div>
    </OptionScreenTemplate>
  );
};
