export const formatDuration = (seconds: number): string => {
  let sec: string | number = Math.floor(seconds);
  let min: string | number = Math.floor(sec / 60);
  min = min >= 10 ? min : "0" + min;
  sec = Math.floor(sec % 60);
  sec = sec >= 10 ? sec : "0" + sec;
  return min + ":" + sec;
};

export const formatDurationMinute = (seconds: number): string => {
  return Math.round(Math.round(seconds) / 60).toString() + " minutes";
};

export const getLocalDate = (): string => {
  const now = new Date(),
    tzo = -now.getTimezoneOffset(),
    dif = tzo >= 0 ? "+" : "-",
    pad = function (num: number) {
      const norm = Math.abs(Math.floor(num));
      return (norm < 10 ? "0" : "") + norm;
    };
  return (
    now.getFullYear() +
    "-" +
    pad(now.getMonth() + 1) +
    "-" +
    pad(now.getDate()) +
    "T" +
    pad(now.getHours()) +
    ":" +
    pad(now.getMinutes()) +
    ":" +
    pad(now.getSeconds()) +
    dif +
    pad(tzo / 60) +
    ":" +
    pad(tzo % 60)
  );
};

// Parse durations and return string representation
// i.e. [5, 10, 15] ==> 5 / 10 / 15 mins
export const getMinutes = (durations: number[]): string => {
  if (durations.length < 1) {
    return "";
  } else if (durations.length === 1) {
    return durations[0].toString();
  }
  const durationStr: string[] = [];
  durations.forEach((duration) => durationStr.push(`${duration}`));
  return durationStr.join(" / ").toString();
};
