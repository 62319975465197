import React from "react";
import { ReactComponent as OvalIcon1 } from "../../../assets/icons/oval-1.svg";
import { ReactComponent as OvalIcon2 } from "../../../assets/icons/oval-2.svg";

import "./styles.scss";

export const IconOvals = () => {
  return (
    <>
      <OvalIcon1 className="icon-oval-1" />
      <OvalIcon2 className="icon-oval-2" />
    </>
  );
};
