import React from "react";
import { useTranslation } from "react-i18next";

import { Tool } from "./Tool";

import "./styles.scss";

export const AdditionalToolsSection = () => {
  const { t } = useTranslation();

  return (
    <section className="section-explore-more">
      <div className="section-explore-more-inner">
        <h1 className="section-explore-more-title center">
          {t("additionalToolsTitle")}
        </h1>

        <div className="tools-container">
          <Tool
            title={t("hmpTitle")}
            description={t("hmpBody")}
            buttonText={t("hmpBtnText")}
            externalLink={"https://hminnovations.org/meditation-app"}
          />
          <Tool
            title={t("blogTitle")}
            description={t("blogBody")}
            buttonText={t("blogBtnText")}
            externalLink={"https://hminnovations.org/blog"}
          />
        </div>
      </div>
    </section>
  );
};
