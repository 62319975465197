import React from "react";

import { AUDIO_STATUS } from "../../../../constants";
import { IconPlay, IconPause } from "../../../Icons";

type ToggleIconProps = {
  status: AUDIO_STATUS;
};

export const ToggleIcon = ({ status }: ToggleIconProps) => {
  const { INITIAL, PLAYING, PAUSED } = AUDIO_STATUS;

  switch (status) {
    case INITIAL:
      return <IconPlay className="toggle-icon initial" />;
    case PLAYING:
      return <IconPause className="toggle-icon playing" />;
    case PAUSED:
      return <IconPlay className="toggle-icon paused" />;
    default:
      return <IconPlay className="toggle-icon initial" />;
  }
};
