import React from "react";
import { Link } from "react-router-dom";

import "./styles.scss";

type ButtonProps = {
  disabled?: boolean;
  loading?: boolean;
  // Set href if button is a Link
  href?: string;
  secondary?: boolean;
  openInNewTab?: boolean;
  classNames?: string;
  noStyling?: boolean;
} & React.ComponentPropsWithRef<"button">;

const defaultButtonProps: ButtonProps = {
  disabled: false,
  loading: false,
};

export const Button: React.FC<ButtonProps> = ({
  children,
  disabled,
  loading,
  secondary,
  href,
  openInNewTab,
  classNames,
  noStyling,
  ...otherProps
}) => {
  const classNames_ = `btn-custom ${secondary ? "btn-custom-secondary" : ""} ${
    classNames ? classNames : ""
  } ${noStyling ? "btn-no-styling" : ""}`;
  return !href ? (
    <button
      className={classNames_}
      disabled={disabled || loading}
      {...otherProps}
    >
      {children}
    </button>
  ) : (
    <Link
      to={openInNewTab ? { pathname: href } : href}
      className={classNames_}
      target={`${href && openInNewTab ? "_blank" : ""}`}
    >
      {children}
    </Link>
  );
};

// Set default props for Button
Button.defaultProps = defaultButtonProps;
