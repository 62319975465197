// React Google Analytics
import ReactGA from "react-ga4";
import { createBrowserHistory, History } from "history";
import { getLocalDate } from "../utils/string-formatter";

type SendEvent = {
  category: string;
  action: string;
  label?: string;
  value?: number;
};

declare global {
  interface Window {
    gaGlobal: any;
  }
}

// Passed into Router
export const getHistory = (): History => {
  const history = createBrowserHistory();
  history.listen((location) => {
    ReactGA.set({ page: location.pathname }); // Update user's current page
    ReactGA.send({ hitType: "pageView", page: location.pathname }); // Record a page view for the given page
  });
  return history;
};

export const initializeGA = (): void => {
  const trackingId = process.env.REACT_APP_GA_TRACKING_ID || "";
  if (!trackingId) {
    throw new Error("Invalid tracking id");
  }
  ReactGA.initialize(trackingId);
};

export const getClientId = (): string => {
  const clientId = window.gaGlobal?.vid || "";
  // . is added in the end to prevent GA4 from converting the client id to exponential
  return `${clientId.toString()}.`;
};

export const sendEvent = ({ category, action, label, value }: SendEvent) => {
  // Set custom dimensions to update current time of current event
  //  and keep track of the client id
  const otherDimensions = {
    timestamp_custom: getLocalDate(),
    clientId_custom: getClientId(),
  };
  ReactGA.event(action, {
    event_category: category,
    event_label: label,
    value,
    ...otherDimensions,
  });
};
