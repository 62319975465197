const IMG_DIR = "https://d1lxivv860f2t4.cloudfront.net/img/";

// English speakers
export const SPEAKER_EN = {
  cort: { name: "Cortland Dahl", imgUrl: IMG_DIR + "cort.jpg" },
  daniela: { name: "Daniela Labra Cardero", imgUrl: IMG_DIR + "daniela.jpg" },
  ronne: { name: "Ronné Wingate Sims", imgUrl: IMG_DIR + "ronne.jpg" },
  jay: { name: "Jay Vidyarthi", imgUrl: IMG_DIR + "jay.jpg" },
};

// Spanish speakers
export const SPEAKER_ES = {
  abismael: { name: "Abismael Resendiz", imgUrl: IMG_DIR + "abismael.jpg" },
  fernanda: { name: "Fernanda Torres", imgUrl: IMG_DIR + "fernanda.jpg" },
};

// TODO: Mandarin speakers
export const SPEAKER_CN = {
  tbd: { name: "TBD", imgUrl: "" },
};
