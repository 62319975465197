import React from "react";
import backIcon from "../../../assets/icons/icon-arrow-back.png";

import { Button } from "../../Button";

import "./styles.scss";

type Props = {
  onClick: () => void;
  light?: boolean;
  large?: boolean;
};

export const BackButton = (props: Props) => {
  return (
    <Button classNames="btn-back" onClick={props.onClick} noStyling>
      <img className="icon-back" src={backIcon} alt="back-icon" />
    </Button>
  );
};
