import { AudioMetadata } from "../types";

export const sortAudiosByDuration = (
  audios: AudioMetadata[],
  ascending: boolean
) => {
  const updatedAudios = [...audios];
  // Sort audios in ascending order of duration
  if (ascending) {
    updatedAudios.sort((a: AudioMetadata, b: AudioMetadata) =>
      a.options.durations[0] > b.options.durations[0]
        ? 1
        : b.options.durations[0] > a.options.durations[0]
        ? -1
        : 0
    );
  }
  // Sort audios in descending order of duration
  else {
    updatedAudios.sort((a: AudioMetadata, b: AudioMetadata) =>
      a.options.durations[0] < b.options.durations[0]
        ? 1
        : b.options.durations[0] < a.options.durations[0]
        ? -1
        : 0
    );
  }
  return updatedAudios;
};
