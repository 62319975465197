import React from "react";
import playIcon from "../../../assets/icons/icon-play.svg";

type Props = {
  className?: string;
};

export const IconPlay = (props: Props) => {
  return (
    <img
      className={`icon-play-pause icon-play ${props.className}`}
      src={playIcon}
      alt="play-icon"
    />
  );
};
