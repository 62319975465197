import React from "react";
import { IconStars } from "../Icons";

import "./styles.scss";

type Props = {
  isPageTemplate?: boolean;
};

export const Layout: React.FC<Props> = ({ children, isPageTemplate }) => {
  return (
    <>
      <div className="layout">
        <IconStars />
        {/* Render shorter layout for page template */}
        {isPageTemplate ? (
          <div className="layout-shorter">{children}</div>
        ) : (
          <div className="layout-inner">{children}</div>
        )}
      </div>
    </>
  );
};
