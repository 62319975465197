import React from "react";
import rewindIcon from "../../../assets/icons/icon-rewind.png";

export const IconRewind = () => {
  return (
    <img
      className={`icon-forward-rewind icon-rewind`}
      src={rewindIcon}
      alt="rewind-icon"
    />
  );
};
