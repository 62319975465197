import React from "react";
import { CloseButton, BackButton } from "../../Buttons";
import { Layout } from "../../Layout";
import { useNavigation } from "../../../hooks/navigation";

import "./styles.scss";

type Props = {
  onClose: () => void;
  onBack?: () => void;
  hasPrevScreen?: boolean;
};

export const OptionScreenTemplate: React.FC<Props> = (props) => {
  useNavigation();

  return (
    <Layout>
      <div className="option-screen">
        {props.hasPrevScreen && props.onBack && (
          <BackButton onClick={props.onBack} light large />
        )}
        <CloseButton onClose={props.onClose} />
        <div className="option-wrap">{props.children}</div>
      </div>
    </Layout>
  );
};
