import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import {
  AvailableLanguages,
  AudioMetadata,
  AudioOptionsSelected,
  AvailableScreens,
} from "../../types";
import { PracticeCard } from "../Cards";
import { Button } from "../Button";
import { sortAudiosByDuration, isDev } from "../../utils";
import { Header } from "../Header";
import {
  ModeScreen,
  DurationScreen,
  SpeakerScreen,
  AudioScreen,
} from "../OptionScreens";
import { Layout } from "../Layout";
import { AdditionalToolsSection } from "../sections/AdditionalToolsSection";
import { LinksToPages } from "../LinksToPages";
import { IconOvals, IconOvalsBottom } from "../Icons";

import "./styles.scss";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type Props = {
  language: AvailableLanguages;
};

const DEFAULT_SELECTED_OPTION: AudioOptionsSelected = {
  id: "-1",
  mode: "sitting",
  duration: -1,
  speaker: { name: "", imgUrl: "" },
};

const DEFAULT_SELECTED_AUDIO: AudioMetadata = {
  id: "-1",
  category: "core",
  title: "",
  description: "",
  options: {
    modes: ["sitting"],
    speakers: [{ name: "invalid", imgUrl: "" }],
    durations: [-1],
  },
};

export const PageTemplate = (props: Props) => {
  const { t, i18n } = useTranslation();
  // Audio metadata
  const [audios, setAudios] = useState<AudioMetadata[]>([]);
  const [isAscendingOrder, setIsAscendingOrder] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState<AudioOptionsSelected>(
    DEFAULT_SELECTED_OPTION
  );
  const [selectedAudio, setSelectedAudio] = useState<AudioMetadata>(
    DEFAULT_SELECTED_AUDIO
  );
  // TODO: revert temporary uri
  const [audioUri, setAudioUri] = useState<string>(
    "https://d1lxivv860f2t4.cloudfront.net/test.mp3"
  );
  const [screenStack, setScreenStack] = useState<AvailableScreens[]>([]);
  const [currentStackIndex, setCurrentStackIndex] = useState(-1);
  // Showing screens for selecting options
  const [showModeScreen, setShowModeScreen] = useState(false);
  const [showDurationScreen, setShowDurationScreen] = useState(false);
  const [showSpeakerScreen, setShowSpeakerScreen] = useState(false);
  const [showAudioScreen, setShowAudioScreen] = useState(false);

  useEffect(() => {
    // Set language from props
    i18n.changeLanguage(props.language);
    // Set audios from language
    setAudios(i18n.getResourceBundle(props.language, "audios"));
  }, [i18n, props.language]);

  useEffect(() => {
    const { id, duration, speaker, mode } = selectedOptions;
    // Handle audio uri when selected option is valid
    if (id !== "-1" && duration !== -1 && speaker.name) {
      // Find corresponding uri and set it to audioUri
      audios.forEach((audio) => {
        if (audio.id === id) {
          if (audio.optionsMap && audio.optionsMap.length > 0) {
            audio.optionsMap.forEach((option) => {
              if (
                option.mode === mode &&
                option.duration === duration &&
                option.speaker.name === speaker.name
              ) {
                setAudioUri(option.mediaUrl);
              }
            });
          }
        }
      });
    }
  }, [selectedOptions, audios]);

  const renderPracticeCards = () => {
    if (audios.length < 1) {
      return <div>Loading...</div>;
    }

    return audios.map((audio: AudioMetadata) => {
      return (
        <PracticeCard
          key={audio.id}
          id={audio.id}
          title={audio.title}
          durations={audio.options.durations}
          isCorePractice={audio.category === "core"}
          modes={audio.options.modes}
          speakers={audio.options.speakers}
          selectCard={() => handleSelectCard(audio)}
        />
      );
    });
  };

  const renderArrowIcon = () => {
    if (!isAscendingOrder) {
      return (
        <FontAwesomeIcon
          className="icon-fa"
          icon={faArrowUp as IconProp}
          size={"sm"}
        />
      );
    }
    return (
      <FontAwesomeIcon
        className="icon-fa"
        icon={faArrowDown as IconProp}
        size={"sm"}
      />
    );
  };

  // Sort audios by duration
  const sortByDuration = () => {
    if (audios) {
      if (isAscendingOrder) {
        // Sort by descending
        setAudios(sortAudiosByDuration(audios, false));
        setIsAscendingOrder(false);
      } else {
        // Sort by ascending
        setAudios(sortAudiosByDuration(audios, true));
        setIsAscendingOrder(true);
      }
    }
  };

  const selectOption = (property: keyof AudioOptionsSelected, value: any) => {
    setSelectedOptions((prevOptions) => {
      return { ...prevOptions, [property]: value };
    });
    handleShowNextScreen();
  };

  const handleCloseScreens = () => {
    setShowModeScreen(false);
    setShowDurationScreen(false);
    setShowSpeakerScreen(false);
    setShowAudioScreen(false);
    // Reset default options
    setSelectedOptions(DEFAULT_SELECTED_OPTION);
    setSelectedAudio(DEFAULT_SELECTED_AUDIO);
    setScreenStack([]);
    setCurrentStackIndex(-1);
    // TODO: revert test uri
    setAudioUri("https://d1lxivv860f2t4.cloudfront.net/test.mp3");
  };

  // Select option when there is one option available (modes/durations/speakers)
  const setSingleOption = (
    property: keyof AudioOptionsSelected,
    options: any
  ) => {
    if (options.length === 1) {
      selectOption(property, options[0]);
    }
  };

  const handleSelectCard = (audioData: AudioMetadata) => {
    setSelectedAudio(audioData);
    const { modes, durations, speakers } = audioData.options;
    // Set audio options
    selectOption("id", audioData.id);
    setSingleOption("mode", modes);
    setSingleOption("duration", durations);
    setSingleOption("speaker", speakers);

    // Handle screen stack
    if (modes.length > 1) {
      screenStack.push("mode");
    }
    if (durations.length > 1) {
      screenStack.push("duration");
    }
    if (speakers.length > 1) {
      screenStack.push("speaker");
    }
    screenStack.push("audio");
    handleShowNextScreen();
  };

  const hasPreviousScreen = (): boolean =>
    screenStack.length > 1 && currentStackIndex > 0;

  const handleShowNextScreen = () => {
    if (screenStack.length > 0) {
      setCurrentStackIndex((prev) => {
        const newIndex = prev + 1;
        renderScreen(screenStack[newIndex]);
        return newIndex;
      });
    }
  };

  const handleShowPrevScreen = () => {
    if (screenStack.length > 1) {
      setCurrentStackIndex((prev) => {
        const newIndex = prev - 1;
        renderScreen(screenStack[newIndex]);
        return newIndex;
      });
    }
  };

  const renderScreen = (screen: AvailableScreens) => {
    if (screen === "mode") {
      setShowModeScreen(true);
    } else if (screen === "duration") {
      setShowDurationScreen(true);
    } else if (screen === "speaker") {
      setShowSpeakerScreen(true);
    } else if (screen === "audio") {
      setShowAudioScreen(true);
    }
  };

  if (showModeScreen) {
    return (
      <ModeScreen
        onHide={() => setShowModeScreen(false)}
        selectOption={selectOption}
        modes={selectedAudio.options.modes}
        onClose={() => handleCloseScreens()}
      />
    );
  }
  if (showDurationScreen) {
    return (
      <DurationScreen
        onHide={() => setShowDurationScreen(false)}
        selectOption={selectOption}
        durations={selectedAudio.options.durations}
        onClose={() => handleCloseScreens()}
        hasPrevScreen={hasPreviousScreen()}
        onBack={handleShowPrevScreen}
      />
    );
  }
  if (showSpeakerScreen) {
    return (
      <SpeakerScreen
        onHide={() => setShowSpeakerScreen(false)}
        selectOption={selectOption}
        speakers={selectedAudio.options.speakers}
        onClose={() => handleCloseScreens()}
        hasPrevScreen={hasPreviousScreen()}
        onBack={handleShowPrevScreen}
      />
    );
  }
  if (showAudioScreen) {
    return (
      <AudioScreen
        audioTitle={selectedAudio.title}
        audioDescription={selectedAudio.description}
        speaker={selectedOptions.speaker}
        mode={selectedOptions.mode}
        onHide={() => setShowAudioScreen(false)}
        onClose={() => handleCloseScreens()}
        audioUri={audioUri}
        selectedDuration={selectedOptions.duration}
        hasPrevScreen={hasPreviousScreen()}
        onBack={handleShowPrevScreen}
      />
    );
  }

  return (
    <>
      <Layout isPageTemplate={true}>
        <Header />
        <IconOvals />
        <div className="page-template">
          <div className="page-template-title-wrap">
            <h1 className="page-template-title">{t("choose a meditation")}</h1>
            <Button classNames="btn-sort" secondary onClick={sortByDuration}>
              {t("sortByDuration")}
              {renderArrowIcon()}
            </Button>
          </div>
          <div className="practice-cards">{renderPracticeCards()}</div>
          <div className="disclaimer">
            <span>{t("disclaimer")}</span>
          </div>
        </div>
      </Layout>
      <div className="icon-oval-wrap">
        <IconOvalsBottom />
      </div>
      <AdditionalToolsSection />
      {isDev && <LinksToPages />}
    </>
  );
};
