import React from "react";
import ReactToolTip, { Place } from "react-tooltip";
import { isMobile } from "react-device-detect";

import "./styles.scss";

type Props = {
  id: string;
  text: string;
  place?: Place;
  hideOnMobile?: boolean;
  children: JSX.Element;
  className?: string;
};

export const Tooltip = (props: Props) => {
  return (
    <div>
      <div
        className={props.className ? props.className : ""}
        data-for={`${props.id ? props.id : ""}`}
        data-tip="React-tooltip"
      >
        {props.children}
      </div>
      <ReactToolTip
        id={`${props.id ? props.id : ""}`}
        place={props.place ? props.place : "bottom"}
        effect="solid"
        disable={props.hideOnMobile && isMobile ? true : false}
        uuid="mytt"
        delayShow={500}
      >
        <span>{props.text}</span>
      </ReactToolTip>
    </div>
  );
};
