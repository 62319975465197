import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CloseButton, BackButton } from "../../Buttons";
import { MainPlayer } from "../../AudioPlayer";
import { Speaker, Mode } from "../../../types";
import { Layout } from "../../Layout";
import { sendEvent } from "../../../utils";
import { EVENT_CATEGORIES } from "../../../constants";

import "./styles.scss";

type Props = {
  audioTitle: string;
  audioDescription: string;
  audioUri: string;
  speaker: Speaker;
  mode: Mode;
  selectedDuration: number;
  hasPrevScreen?: boolean;
  onHide: () => void;
  onClose: () => void;
  onBack?: () => void;
};

export const AudioScreen = (props: Props) => {
  const history = useHistory();

  const [elapsedTime, setElapsedTime] = useState<number>(0);

  // Send elapsed time on closing tab
  useEffect(() => {
    window.addEventListener("beforeunload", sendAudioElapsed);
    return () => window.removeEventListener("beforeunload", sendAudioElapsed);
  });

  useEffect(() => {
    const stateName = "optionScreen";
    // Push a history if not have been pushed
    if (history.location.state !== stateName) {
      history.push({ state: stateName });
    }
    return history.listen((location) => {
      if (history.action === "POP") {
        sendAudioElapsed();
        window.location.reload();
      }
    });
    // eslint-disable-next-line
  }, [history]);

  const handleClose = () => {
    sendAudioElapsed();
    props.onClose();
  };

  const handleBack = () => {
    if (props.hasPrevScreen && props.onBack) {
      sendAudioElapsed();
      props.onBack();
    }
  };

  // action or label recorded in Google Analytics
  const getEventString = () => {
    const modeString = props.mode === "sitting" ? "sp" : "ap";
    return `${props.audioTitle}/${modeString}/${props.selectedDuration}/${
      props.speaker.name.split(" ")[0]
    }`;
  };

  const sendAudioElapsed = () => {
    // Track elapsed time at lease one second
    if (elapsedTime && elapsedTime > 0) {
      sendEvent({
        category: EVENT_CATEGORIES.audioElapsed,
        action: `ELAPSED - ${getEventString()}`,
        label: `${elapsedTime.toString()} seconds listened`,
        value: elapsedTime,
      });
      setElapsedTime(0);
    }
  };

  const renderAudioPlayer = () => {
    return (
      <MainPlayer
        src={props.audioUri}
        title={props.audioTitle}
        selectedDuration={props.selectedDuration}
        selectedSpeakerName={props.speaker.name}
        selectedMode={props.mode}
        elapsedTime={elapsedTime}
        setElapsedTime={setElapsedTime}
        sendAudioElapsed={sendAudioElapsed}
      />
    );
  };

  return (
    <Layout>
      <div className="audio-screen">
        {props.hasPrevScreen && props.onBack && (
          <BackButton onClick={handleBack} />
        )}
        <CloseButton onClose={handleClose} />
        <div className="audio-screen-inner">
          <div className="audio-screen-info">
            <h1 className="audio-screen-info-title">{`${props.audioTitle}`}</h1>
            <p className="audio-screen-info-description">
              {props.audioDescription}
            </p>
          </div>
          {/* TODO: remove once all audios are ready */}
          {props.audioUri.includes("test.mp3") && (
            <p className="center my-1">{`- Below audio file is for testing - `}</p>
          )}
          {renderAudioPlayer()}
        </div>
      </div>
    </Layout>
  );
};
