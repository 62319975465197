import React from "react";
import { LinksToPages } from "../../components/LinksToPages";

export const PageNotFoundPage = () => {
  return (
    <div className="page-not-found-page">
      <h1 className="center p-1">There is an error</h1>
      <p className="center my-1">
        We're sorry. The page you requested could not be found. Please go back
        to other page.
      </p>
      <LinksToPages />
    </div>
  );
};
